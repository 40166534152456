import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby'
import ServicesDetails from 'components/ServicesContent/ServicesDetails.jsx'
// import a_ref from './a_ref.css'
import ServicesHeader from '../../StaticQueries/ServicesHeader'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


class Header4Tabs extends React.Component {
	render() {
		return (
			<div style={{ display: "flex", background: "#009be5", marginBottom: "0" }} >
				<nav>
					<ul className={styles.headerMenu} style={{ display: "flex", flex: 1 }}>
						{this.props.headerProps.map(item => (
							<li key={item.name} style={{ listStyleType: `none`, padding: `1rem` }} >
 					            {/* <a href={`services${item.link}`}> {item.name} </a> */}
								{/* <Link style={{ color: `blue` }, { textDecoration: 'none' }} to={`/services${item.link}`}> {item.name} </Link> */}
								<Link style={{ 	color: `#fff`, 
											   	textDecoration: 'none',
											   	backgroundColor: '#2fc974',
											   	boxShadow: '1px 5px 1px darkgreen',
											   	padding: '1em 1.5em',
											   	position: 'relative',
											   	textTransform: 'none',
											   	display: 'block',
											   	textAlign: 'center',
												borderRadius: '8px',
											}} to={`/services${item.link}`}> {item.name}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</div>
		);
	}
}

const styles = theme => ({
	paper: {
		maxWidth: 1700,
		margin: 'auto',
		// overflow: 'hidden',
	},
	searchBar: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	searchInput: {
		fontSize: theme.typography.fontSize,
	},
	block: {
		display: 'block',
	},
	// addUser: {
	// 	marginRight: theme.spacing.unit,
	// },
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		// padding: '48px 36px 0',
		padding: '48px 36px 0',
	},
})

const listItems = [
	{ link: '#Consulting', name: "Consulting" },
	{ link: '#Development', name: "Development" },
	{ link: '#MusicComposition', name: "Music Composition" }
];

function ServicesContent({ classes }) {
	return (
		<>
			<div className={classes.container}>
				<div style={{ display: "flex", backgroundImage: 'linear-gradient(red, yellow)', margin: "0" }} >
					<nav>
						<ul className={styles.headerMenu} style={{ display: "flex", flex: 1 }}>
							{listItems.map(item => (
								<li key={item.name} style={{ listStyleType: `none`, padding: `1rem` }} >
									<Link style={{
										color: `#fff`,
										textDecoration: 'none',
										backgroundColor: '#2fc974',
										boxShadow: '1px 5px 1px darkgreen',
										padding: '0.5em 0.5em',
										position: 'relative',
										textTransform: 'none',
										display: 'block',
										textAlign: 'center',
										borderRadius: '8px',
									}} to={`/services${item.link}`}> {item.name} </Link>
								</li>
							))}
						</ul>
					</nav>
				</div>

				<Paper className={classes.paper}>
					<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
						{/* <h1 align="center" style={{color: 'green'}}>-= C°O°N°S°U°L°T°I°N°G ≡≡≡ D°E°V°E°L°O°P°M°E°N°T =-</h1> */}
						<ServicesHeader/>
					</AppBar>

					<div className={classes.contentWrapper} style={{fontSize: '120%', color: 'blue'}}>
						{/* <Typography color="textSecondary" align="justify"> */}
                        <typography color="textPrimary" align="justify">
							<ServicesDetails/>
						</typography>
						{/* </Typography> */}
					</div>
				</Paper>
			</div>
		</>
	)
}

ServicesContent.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ServicesContent)
