import React from 'react';
import { Link } from 'gatsby'
import containerStyles from "./container.module.css"

import imgCot from '../../../static/images/logoCott.jpg'
import imgDal from '../../../static/images/logoDalhousie.jpg'
import imgEsp from '../../../static/images/logoHelenaEsperantoAsocio.jpg'
import imgITG from '../../../static/images/logoITG.png'
import imgJP from '../../../static/images/logoJ&P.jpg'
import imgNUA from '../../../static/images/logoNUAthens.png'
import imgSch from '../../../static/images/logoSchulichYork.jpg'
import imgSen from '../../../static/images/logoSenecaCollege.png'
import imgSpe from '../../../static/images/logoSpeedware.jpg'
import imgTDB from '../../../static/images/logoTDBank.jpg'
import imgTDS from '../../../static/images/logoTDSI.png'
import imgUBS from '../../../static/images/logoUBS.png'
import imgRM from '../../../static/images/logoRobbins&Myers.jpg'
import imgCVWordCloud from "../../../static/images/Toli-CV_WordCloud-NEW.jpg"



class ServicesDetails extends React.Component {
  render() {
    return (
      <div id="TopAlmost" className={containerStyles.container}>
        <div id="logos">
          <pre>{"\n\n\n"}</pre>
          {/* <h2> */}
            Among the businesses and institutions that absorbed previous project work are the ones 
            highlighted (through their logos) below. The specifics of the related projects can be 
            found under the '<b>Projects</b>' page.
        {/* </h2> */}
          <pre>{"\n"}</pre>
        </div>
        <div>
          <span className="image main">
            <img src={imgITG} alt="logoITG.png" width="20%" />
            <img src={imgUBS} alt="logoUBS.png" width="20%" />
            <img src={imgSen} alt="logoSenecaCollege.png" width="20%" />
            <img src={imgTDS} alt="logoTDSI.png" width="20%" />
            <img src={imgTDB} alt="logoTDBank.jpg" width="20%" />
            <img src={imgCot} alt="logoCott.jpg" width="20%" />
            <img src={imgSpe} alt="logoSpeedware.jpg" width="20%" />
            <img src={imgRM} alt="logoRobbins&Myers.jpg" width="20%" />
            <img src={imgJP} alt="logoJ&P.jpg" width="20%" />
            <img src={imgEsp} alt="logoHelenaEsperantoAsocio.jpg" width="20%" />
            <img src={imgSch} alt="logoSchulichYork.jpg" width="20%" />
            <img src={imgDal} alt="logoDalhousie.jpg" width="20%" />
            <img src={imgNUA} alt="logoNUAthens.png" width="20%" />
          </span>
          <pre>{"\n"}</pre>
        </div>

        <div id="Consulting">
          <pre>{"\n\n\n"}</pre>
          <h1>CONSULTING:</h1>
          <hr/><hr/>
        </div>
        <div>
          {/* <h2> */}
            The company has extensive experience in <b>Computer Systems Consulting</b> covering a wide variety
            of industries, including Typesetting, Pulp &amp; Paper, Civil Engineering, Technology
            Transfer Organizations, Beverages Industry, Financial, Banking and  Investment Technology
            Industries, as well as in the disciplines of Architecture &amo; Surveying, Technical Writing,
            Academic Instruction and Computer Systems User Training &amp; Support.
            <br/><br/>
            The company includes in its expertise the effective Management of Information Systems (with
            the principal engineer being a holder of a related <b>M.B.A.</b> degree from the prestigious <b>Schulich
            School of Business</b>) and offers self-motivated and detail-oriented resources exhibitting a
            team-player spirit and high service orientation.
            <br/>
            All project work undertaken to-date has been highly successful and the company has thrived
            with concurrent projects in fast-paced environments characterized by high pressure deliverabled,
            tight deadlines and constant change.
            <br/><br/>
            In addition, the company has been exceedingly successful in managing relationships with a variety
            of counterparties within its client  base and outside.
            <br/><br/>
            It is the combination of all these qualities of the company that ensure risk mitigation and the
            delivery of high-quality products over a variety of technology platforms and for a broad range
            of internal and external stakeholders!
          {/* </h2> */}
          <hr/>
        </div>

        <div>
            {/* <Link to="services#Top">≡ Top</Link> */}
            <a href="services#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
          </div>

        <div id="Development">
          <pre>{"\n\n\n"}</pre>
          <h1>DEVELOPMENT:</h1>
          <hr/><hr/>
        </div>
        <div>
          {/* <h2> */}
            The software development has been the core focus of this firm and encapsulates <b>more than
            30 years</b> of related experience, accummulated in a multitude of projects across quite a few
            industries.
            <br/><br/>
            Here is reference of the software development related <span style={{ color: 'blue' }}>skills</span> that 
            have been used to-date:
            <br/><br/>

            <p style={{ color: 'green', padding: '0% 10%'}}>
              IT Management &amp; Team Leadership<span style={{ color: 'red' }}>&#x273D;</span> Object Oriented Design <span style={{ color: 'red' }}>&#x273D;</span> SDLC <span style={{ color: 'red' }}>&#x273D;</span> Agile Methodologies <span style={{ color: 'red' }}>&#x273D;</span>
              Teaching <span style={{ color: 'red' }}>&#x273D;</span> Web Development <span style={{ color: 'red' }}>&#x273D;</span> Responsive Web Design <span style={{ color: 'red' }}>&#x273D;</span> ASP.NET <span style={{ color: 'red' }}>&#x273D;</span> JavaScript <span style={{ color: 'red' }}>&#x273D;</span> NodeJS
              React <span style={{ color: 'red' }}>&#x273D;</span> Redux <span style={{ color: 'red' }}>&#x273D;</span> Express <span style={{ color: 'red' }}>&#x273D;</span> GraphQL <span style={{ color: 'red' }}>&#x273D;</span> ApolloJS <span style={{ color: 'red' }}>&#x273D;</span> jQuery <span style={{ color: 'red' }}>&#x273D;</span> AngularJS <span style={{ color: 'red' }}>&#x273D;</span>
              Neo4j <span style={{ color: 'red' }}>&#x273D;</span> Vulcan <span style={{ color: 'red' }}>&#x273D;</span> Webpack <span style={{ color: 'red' }}>&#x273D;</span> AWS <span style={{ color: 'red' }}>&#x273D;</span> SQL <span style={{ color: 'red' }}>&#x273D;</span> Microsoft SQL Server <span style={{ color: 'red' }}>&#x273D;</span> MySQL <span style={{ color: 'red' }}>&#x273D;</span>
              SQLite <span style={{ color: 'red' }}>&#x273D;</span> NoSQL <span style={{ color: 'red' }}>&#x273D;</span> MongoDB <span style={{ color: 'red' }}>&#x273D;</span> Redis <span style={{ color: 'red' }}>&#x273D;</span> Docker <span style={{ color: 'red' }}>&#x273D;</span> System Architecture <span style={{ color: 'red' }}>&#x273D;</span>
              System Administration (Sysadmin) <span style={{ color: 'red' }}>&#x273D;</span> Statistical Data Analysis <span style={{ color: 'red' }}>&#x273D;</span> Trading systems <span style={{ color: 'red' }}>&#x273D;</span>
              Low Latency Development <span style={{ color: 'red' }}>&#x273D;</span> Risk management <span style={{ color: 'red' }}>&#x273D;</span> Software Testing <span style={{ color: 'red' }}>&#x273D;</span> Test automation <span style={{ color: 'red' }}>&#x273D;</span>
              Visual Basic <span style={{ color: 'red' }}>&#x273D;</span> VB.NET <span style={{ color: 'red' }}>&#x273D;</span> C# <span style={{ color: 'red' }}>&#x273D;</span> C <span style={{ color: 'red' }}>&#x273D;</span> C++ <span style={{ color: 'red' }}>&#x273D;</span> STL <span style={{ color: 'red' }}>&#x273D;</span> Perl <span style={{ color: 'red' }}>&#x273D;</span> Java <span style={{ color: 'red' }}>&#x273D;</span>
              Python <span style={{ color: 'red' }}>&#x273D;</span> Pascal <span style={{ color: 'red' }}>&#x273D;</span> R <span style={{ color: 'red' }}>&#x273D;</span> Ruby <span style={{ color: 'red' }}>&#x273D;</span> VBScript <span style={{ color: 'red' }}>&#x273D;</span> Shell scripting <span style={{ color: 'red' }}>&#x273D;</span> VBA <span style={{ color: 'red' }}>&#x273D;</span>
              C Shell <span style={{ color: 'red' }}>&#x273D;</span> Ethernet <span style={{ color: 'red' }}>&#x273D;</span> TCP/IP <span style={{ color: 'red' }}>&#x273D;</span> Sockets Programming <span style={{ color: 'red' }}>&#x273D;</span> HTML <span style={{ color: 'red' }}>&#x273D;</span> HTML5 <span style={{ color: 'red' }}>&#x273D;</span>
              AJAX <span style={{ color: 'red' }}>&#x273D;</span> CSS <span style={{ color: 'red' }}>&#x273D;</span> Bootstrap <span style={{ color: 'red' }}>&#x273D;</span> Git <span style={{ color: 'red' }}>&#x273D;</span> REST <span style={{ color: 'red' }}>&#x273D;</span> XML <span style={{ color: 'red' }}>&#x273D;</span> JSON <span style={{ color: 'red' }}>&#x273D;</span> yaml <span style={{ color: 'red' }}>&#x273D;</span>
              Unified Modeling Language (UML) <span style={{ color: 'red' }}>&#x273D;</span> User Datagram Protocol (UDP) <span style={{ color: 'red' }}>&#x273D;</span> Windows <span style={{ color: 'red' }}>&#x273D;</span> Linux <span style={{ color: 'red' }}>&#x273D;</span>
              Unix <span style={{ color: 'red' }}>&#x273D;</span> Solaris <span style={{ color: 'red' }}>&#x273D;</span> iOS <span style={{ color: 'red' }}>&#x273D;</span> Android <span style={{ color: 'red' }}>&#x273D;</span> Multithreading <span style={{ color: 'red' }}>&#x273D;</span> Jenkins <span style={{ color: 'red' }}>&#x273D;</span> Bugzilla <span style={{ color: 'red' }}>&#x273D;</span>
              Market Data <span style={{ color: 'red' }}>&#x273D;</span> FIX Protocol Development <span style={{ color: 'red' }}>&#x273D;</span> Capital Markets <span style={{ color: 'red' }}>&#x273D;</span> Equities <span style={{ color: 'red' }}>&#x273D;</span> Derivatives <span style={{ color: 'red' }}>&#x273D;</span>
              Disaster Recovery <span style={{ color: 'red' }}>&#x273D;</span> Visio
            </p>
          {/* </h2> */}
          <hr/>

          <div align="center">
            <img src={imgCVWordCloud} alt="CV_WordCloud" width="70%" className="center" />
          </div>
          <hr/>

          <div>
            <hr/>
            {/* <Link to="services#Top">≡ Top</Link> */}
            <a href="services#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
          </div>
        </div>

        <div id="MusicComposition">
          <pre>{"\n\n\n"}</pre>
          <h1>MUSIC COMPOSITION:</h1>
          <hr/><hr/>
        </div>
        <div>
          {/* <h2> */}
            The Music Composition part of this site reflects an effort to host a wide variety of Electronic
            Music pieces (quite a few albums), most of which were created since 2007 and have been
            awaiting mastering and production before they get released to the public or made available
            to Music Supervisors for licensing in the <b>Film</b>, <b>TV</b> and <b>Video Gaming</b> industries.
            <br/><br/>
            The Music Composition portfolio includes instrumental pieces from previous participations in 
            various Electronic Music competitions (including the yearly <b>Roland Synthesizer Contests</b>, 
            pieces for selected <b>School events</b>, the <b>CBC Hockey Anthem Contest</b>, etc.)
            <br/><br/>
            Any new music material may be requested through the <b>'Contact'</b> page of the site.
          {/* </h2> */}
          <hr/>
          {/* <Link to="services#Top">≡ Top</Link> */}
          <a href="services#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
        </div>
      </div>
    );
  }
};

export default ServicesDetails;
