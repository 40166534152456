import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import ServicesContent from 'components/ServicesContent'

function ServicesPage({ location }) {
	const pageTitle = location ? location.pathname.replace(/\//g, '') : ''

	return (
		<Layout id="Top" location={location} title={pageTitle}>
			<ServicesContent />
		</Layout>
	)
}

ServicesPage.propTypes = {
	location: PropTypes.object,
}

export default ServicesPage
